import React from "react";
import '../styles/hero.css'
import Header from "./Header";
import hero_image from "../assets/hero_image.png"
import hero_image_back from "../assets/hero_image_back.png"
import heart from "../assets/heart.png"
import calories from  "../assets/calories.png"
import NumberCounter from 'number-counter'
import {motion} from "framer-motion"


 function Hero (){

    const transition = {type: 'spring' , duration : 3}
    const mobile = window.innerWidth<=768 ? true : false
    return(
        <div className="hero" id="home">
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best ad */}
                <div className="the-best-ad">
                    <motion.div
                    initial= {{left: mobile ? "165":'238px'}}
                    whileInView={{left:'8px'}}
                    transition = {{...transition, type: 'tween'}}
                    >

                    </motion.div>
                    <span className="">the best fitness club in the town</span>
                </div>
               {/* hero heading */}
               <div className="hero-text"> 
                    <div>
                        <span className="strocke-text">Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal body</span>
                       
                    </div> 
                    <div>
                        <span>
                            in here we will help you to shape and build
                            live up you life to fullest
                        </span>
                    </div>
               </div>
               {/* figures */}
               <div className="figures" >
                <div>
                    <span><NumberCounter 
                    end={140}
                    start={100}
                    delay='4'
                    preFix='+'/></span>
                    <span>expert coaches</span>
                </div>
                <div>
                    <span><NumberCounter 
                    end={978}
                    start={100}
                    delay='4'
                    preFix='+'/></span>
                    <span>members joined</span>
                </div>
                <div>
                    <span><NumberCounter 
                    end={50}
                    start={10}
                    delay='4'
                    preFix='+'/></span>
                    <span>fitness programs</span>
                </div>

               </div>
                {/* hero-buttons */}
                <div className="hero-buttons">
                    <button className="btn">get started</button>
                    <button className="btn">learn more</button>
                </div>

            </div>

            <div className="right-h">
               <button className="btn">join now</button>
                <motion.div
                initial={{right: "-1rem"}}
                 whileInView={{right:"4rem"}}
                 transition = {{transition}}
                 className="heart_rate">
               
                <img src={heart} alt="" />
                <span>Heart rate</span>
                <span>116 ppm</span>

                </motion.div>

               <motion.img
               initial={{right: "6rem"}}
               whileInView={{right:"14rem"}}
               transition={transition} 
               src={hero_image} alt="" className="hero-image "/>
               <img src={hero_image_back} alt="" className="hero-image-black "/>
               {/* calories */}
               <motion.div 
                 initial={{right: "16rem"}}
                 whileInView={{right:"80rem"}}
                 transition = {{transition}}
                 className="calories">
                    <img src={calories} alt="" className=""/>
                    <div>
                        <span>calorie burned</span>
                        <span>220 kcal</span>
                    </div>
               </motion.div>
               
            </div>
        </div>
    )
}

export default Hero