import React from "react";
import { useRef } from "react";
import "../styles/Join.css"
import emailjs from '@emailjs/browser'



function Join (){

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_178mdzs', 'Ytemplate_ic3b2hw', form.current, '4v2f4Gg-M0MD20G5z')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

    return(
        <div className="join" id="join-us">
            <div className="left-j">
                <hr />
                <div>
                    <span className="strocke-text">READY TO</span>
                    <span>LEVEL UP</span>
                </div>
                <div>
                    <span >YOU BODY</span>
                    <span className="strocke-text">WITH US?</span>
                </div>
            </div>
            <div className="right-j">
                <form action="" className="email-container" onSubmit={sendEmail}>
                    <input type="text" name="user_email" placeholder="emter you mail"/>
                    <button className="btn btn-j" >join now</button>
                </form>
            </div>
        </div>
    )
}

export default Join