import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/App.css'
// import App from './App';
import Hero from './components/Hero';
import Programs from './components/Programs';
import Reasons from './components/Reasons';
import Plans from './components/Plans';
import Testimonial from './components/Testimonial';
import Join from './components/join';
import Footer from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Hero />
        <Programs />
        <Reasons />
        <Plans />
        <Testimonial />
        <Join />
        <Footer />
    </React.StrictMode>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals