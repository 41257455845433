import React from "react";
import plansData from "../data/plansData"
import whiteTick from "../assets/whiteTick.png"
import "../styles/Planss.css"


function Plans (){



    return(
        <div className="plans-container" id="plans">
            <div className="blur plan-blur-1"></div>
            <div className="blur plan-blur-2"></div>
            <div className="programs-header" style={{ gap: "2rem" }}>
                <span className="strocke-text">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="strocke-text">NOW WITHUS</span>
            </div>

            {/* plan card */}

            <div className="plans">
                {
                    plansData.map((plan, i) => (
                        <div className="plan" key={i}>
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>{plan.price}</span>
                            <div className="features">
                                {plan.features.map((feature, i)=> (

                                    <div className="feature">
                                        <img src={whiteTick} alt="" />
                                        <span key={i}>{feature}</span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <span>see more benr </span>
                                </div>
                           <button className="btn">join now</button>
                        </div>
                         
                    ))
                }
            </div>
        </div>

        
    )
}


export default Plans