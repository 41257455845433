import React from "react";
import "../styles/Footer.css"
import Github from "../assets/github.png"
import Instagram from "../assets/instagram.png"
import LinkedIn from "../assets/linkedin.png"
import logo1 from "../assets/logo1.png"


function Footer(){


    return(
        <div className="footer-container">
            <hr />
            <div className="footer">
                <div className="social-links">
                    <img src={Github} alt="" />
                    <img src={Instagram} alt="" />
                    <img src={LinkedIn} alt="" />
                </div>
          
            <div className="logo-f">
                <img src={logo1} alt="" />
            </div>
        </div>

        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
    </div>
    )
}

export default Footer
