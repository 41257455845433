import React from "react";
import "../styles/programs.css"
import {programsData} from "../data/programsData"
import rightArrow from "../assets/rightArrow.png"




function Programs (){



    return(
        <div className="programs" id="programs">
            <div className="programs-header">
                <span className="strocke-text">explore our</span>
                <span>programs</span>
                <span className="strocke-text">to shape you</span>
            </div>

            {/* programsData */}
            <div className="programs-categorie">
                {programsData.map((Program)=> (
                    <div className="categories">
                        {Program.image}
                        <span>{Program.heading}</span>
                        <span>{Program.details}</span>
                        <div className="join-now">
                            <span>join now</span>
                            <img src={rightArrow} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Programs